import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import vuetifyConfig from './vuetifyConfig';

Vue.use(Vuetify);

export default function (siteDataConfig) {
    siteDataConfig = siteDataConfig || {};
    const config = vuetifyConfig(siteDataConfig);
    const vuetify =  new Vuetify(config);
    Vue.prototype.$vuetify = vuetify.framework;
    return vuetify;
}
