//IE 11 Stuff
// Transpiler for older browsers
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import store from './store';
import router from './router';
import DefaultPages from './router/DefaultPages';
import App from './App.vue';
import './plugins/cookies';
import './plugins/perfectScrollbar';
import './plugins/toasted';
import './plugins/vueperslides';
import './plugins/masonry';
import './plugins/vue-meta';
import vuetify from './plugins/vuetify';

import {
    LoadingSplashV1,
    AxiosSetup,
    TemplateRegistry,
    RootStoreModule,
    UserStoreModule,
    ConfigStoreModule,
    FeatureStoreModule,
    FolderStoreModule,
    DistributionStoreModule,
    NotificationStoreModule,
    FollowStoreModule,
    NavbarStoreModule,
    FilterConfigurationStoreModule,
    UserEventProcessorModule,
    ClientTranslationStoreModule,
    FilterStoreModule,
    WidgetStoreModule,
    PolicyStoreModule,
} from '@bluematrix/ui-library';

setTimeout(() =>{
    const allowedOrigin = ['localhost', 'portal-int.bluematrix', 'portal-qa.bluematrix'];
    const hasAllowsOrigin = (origin = '') => allowedOrigin.some( o => origin.indexOf(o) !== -1);
    if (window.location){
        console.log('hasAllowsOrigin',window.location.origin, hasAllowsOrigin(window.location.origin));
    }
    Vue.config.devtools = !!(window && window.location && hasAllowsOrigin(window.location.origin));
}, 3000);
// Vue.config.devtools = true;

const errorMessageHandler = (headline, detail, bmRequestId) => {
    const vm = new Vue({vuetify: vuetify()});
    const options = {
        icon: 'fa-exclamation'
    };
    const replaceWithTranslation = (text) => {
        if(text && text.startsWith && text.startsWith('$vuetify')){
            return vm.$vuetify.lang.t(text);
        }
        return text;
    };
    const finalHeadline = replaceWithTranslation(headline);
    const finalDetail = replaceWithTranslation(detail);
    console.log('Error Handler', finalHeadline, finalDetail);
    if (finalHeadline && finalDetail && bmRequestId) {
        const requestIdString = vm.$vuetify.lang.t('$vuetify.errors.general.requestId', bmRequestId);
        vm.$toasted.error(`<div class="text-body-1" style="display: block"><div>${finalHeadline}</div><div class="text-body-2">${finalDetail}</div><div class="text-body-2">${requestIdString}</div></div>`, options);
    } else if (finalHeadline && finalDetail) {
        vm.$toasted.error(`<div class="text-body-1" style="display: block"><div>${finalHeadline}</div><div class="text-body-2">${finalDetail}</div></div>`, options);
    } else if (finalHeadline) {
        vm.$toasted.error(finalHeadline, options);
    } else {
        let headline;
        let details;
        if (vm && vm.$vuetify && vm.$vuetify.lang) {
            headline = vm.$vuetify.lang.t('$vuetify.errors.general.headline');
            details = vm.$vuetify.lang.t('$vuetify.errors.general.details');
        } else {
            headline = 'An error occurred!';
            details = 'Please try again or contact support';
        }
        vm.$toasted.error(`<div class="text-body-1" style="display: block"><div>${headline}</div><div class="text-body-2">${details}</div></div>`, options);
    }
};

AxiosSetup.setup(errorMessageHandler, store, {supportOnly: false, ignore409: false});

const axios = AxiosSetup.axiosInstance;

store.registerModule(RootStoreModule.name, RootStoreModule);
store.registerModule(NavbarStoreModule.name, NavbarStoreModule);
store.registerModule(FilterConfigurationStoreModule.name, FilterConfigurationStoreModule);
store.registerModule(UserStoreModule.name, UserStoreModule);
store.registerModule(ConfigStoreModule.name, ConfigStoreModule);
store.registerModule(FeatureStoreModule.name, FeatureStoreModule);
store.registerModule(FilterStoreModule.name, FilterStoreModule);
store.registerModule(FolderStoreModule.name, FolderStoreModule);
store.registerModule(FollowStoreModule.name, FollowStoreModule);
store.registerModule(DistributionStoreModule.name, DistributionStoreModule);
store.registerModule(NotificationStoreModule.name, NotificationStoreModule);
store.registerModule(UserEventProcessorModule.name, UserEventProcessorModule);
store.registerModule(ClientTranslationStoreModule.name, ClientTranslationStoreModule);
store.registerModule(WidgetStoreModule.name, WidgetStoreModule);
store.registerModule(PolicyStoreModule.name, PolicyStoreModule);

Vue.config.productionTip = false;

//Function to extract query params as an object
const extractQueryParams = () => {
    let params = undefined;
    const query = window.location.search;
    console.log('window.location.search', query);
    if (query && query.includes('?')) {
        const urlSearchParams = new URLSearchParams(query);
        params = Object.fromEntries(urlSearchParams.entries());
    }
    return params;
};

//Placeholder var for the LoadingVM
let loadingVm = undefined;

//Function to destroy the Loading VM
const destroyLoadingVm = () => {
    if (loadingVm) {
        console.log('destroy loading vm');
        loadingVm.$destroy();
        loadingVm.$el.parentNode.removeChild(loadingVm.$el);
        loadingVm = null; //convince gc to do stuff
    }
};

//Function to, if needed, bring up the No Authz VM. Assets loaded async to not impact performance when it's not needed
let noAuthzVm = (vi, message, dark) => {
    destroyLoadingVm();
    console.log('Building AuthZ Failed view...');
    const comp = () => import('./views/AuthorizationFailed.vue');
    new Vue({
        vuetify: vi,
        el: '#failedAuthz',
        render: h => h(comp, {props: {color: 'splash-background-color', message, dark}})
    });
};

let dark = undefined; //We'll hold the splash dark property here
let configObject = undefined;
const getPublicTranslation = async () => {
    return axios.get('/api/public/translation/fetchShorts').then(rs => {
        return rs.data;
    });
};


//1. Fetch Configuration Properties, this allows us to style the Loading VM, No Authz VM, and eventually the site.
store.dispatch(`${ConfigStoreModule.name}/initialisePublic`).then(() => {
    configObject = {
        siteTitle: store.getters[`${ConfigStoreModule.name}/siteTitle`],
        altBackgroundColor: store.getters[`${ConfigStoreModule.name}/altBackgroundColor`],
        brandingColor1: store.getters[`${ConfigStoreModule.name}/brandingColor1`],
        brandingColor2: store.getters[`${ConfigStoreModule.name}/brandingColor2`],
        brandingColor3: store.getters[`${ConfigStoreModule.name}/brandingColor3`],
        focusVisibleOutlineColor: store.getters[`${ConfigStoreModule.name}/focusVisibleOutlineColor`],
        splashBackgroundColor: store.getters[`${ConfigStoreModule.name}/splashBackgroundColor`],
        authorizationFailedMessage: store.getters[`${ConfigStoreModule.name}/authorizationFailedMessage`],
    };
    console.log('config object', configObject);

    //We need to initialise a vuetify object
    let vuetifyInstance = vuetify(configObject);
    dark = store.getters[`${ConfigStoreModule.name}/splashDark`];
    //Now we have the settings we can pull up the Loading VM, this will get destroyed quite quickly.
    loadingVm = new Vue({
        vuetify: vuetifyInstance,
        el: '#splash',
        render: h => h(LoadingSplashV1, {props: {color: 'splash-background-color', dark}})
    });
    const translationsPromise = getPublicTranslation().then((translations) => {
        configObject.clientTranslations = translations;
        vuetifyInstance = vuetify(configObject);
    });
        //2. Prepare the remaining requests that don't require Authorization.
    const siteInitialisePromises = [];
    siteInitialisePromises.push(
        translationsPromise,
        store.dispatch(`${FeatureStoreModule.name}/initialise`),
        store.dispatch(`${FilterConfigurationStoreModule.name}/initialise`),
    );

    const queryParams = extractQueryParams();
    console.log('params', queryParams);
    const isAllowedAccessReportLink = () => {
        const params = queryParams || {};
        const secureLinkId = params['secure-link-id'];
        const hasSecureLinkId =  !!(secureLinkId && secureLinkId.trim().length && /^(([0-9a-z]+-){3,5}[0-9a-z]+)$/g.test(secureLinkId));
        const docId =  params['doc-id'];
        const renderType = params['render-type'];
        const hasGeneric = !!(renderType && docId && docId.trim().length && /^(([0-9a-z]+-){3,5}[0-9a-z]+)$/g.test(docId));
        return !!(params.forward && ['secure-link', 'generic-link'].includes(params.forward) && (hasSecureLinkId || hasGeneric));
    };
    const isAllowedAccessMention = () => {
        const params = queryParams || {};
        //?forward=mention&type=SECURITY&mention=base64({firmId, creatorId})
        return !!(params.forward && params.forward === 'mention' && ['ANALYST', 'SECTOR', 'SECURITY'].includes((params.type || '').toUpperCase()) && params.mention);
    };
    const isAllowedAccessRedirect = () => {
        return isAllowedAccessReportLink() || isAllowedAccessMention();
    };
    let isLiveSessionTrackingDisabled = false;
    //3. When the Site Init promises return
    Promise.all(siteInitialisePromises).then(() => {
        isLiveSessionTrackingDisabled = store.getters[`${FeatureStoreModule.name}/isLiveSessionTrackingDisabled`];
        const publicPagesEnabled = store.getters[`${FeatureStoreModule.name}/isPublicPagesEnabled`];
        let initPromise = undefined;
        //Decide whether we need to show the Public Landing page or not.
        let publicPagePromise;
        if (publicPagesEnabled && !isAllowedAccessRedirect()) {
            //We don't want to show any authN/Z messages here as this call should be silent.
            const publicHandle403 = () => {
                const message = (configObject.authorizationFailedMessage && configObject.authorizationFailedMessage.length > 0 ? configObject.authorizationFailedMessage : '$vuetify.unauthorized.error');
                noAuthzVm(
                    vuetifyInstance,
                    message,
                    dark
                );
            };
            publicPagePromise = store.dispatch('user/validateSession', { handle403: publicHandle403, fail: false }).then((validated) => {
                if (!validated) {
                    console.log('Loading Public view...');
                    TemplateRegistry.init(DefaultPages.templates);
                    console.log('TemplateRegistry keys', TemplateRegistry.getKeys());

                    //If ALLOW_ANONYMOUS_USERS is enabled, and we're accessing the public page, we will need an XSRF token to handle the authorise API
                    if(store.getters[`${FeatureStoreModule.name}/isAllowAnonymousUsersEnabled`]) {
                        store.dispatch('user/xsrf');
                    }

                    initPromise = Promise.all([
                        store.dispatch(`${NavbarStoreModule.name}/initialisePublic`),
                        store.dispatch(`${PolicyStoreModule.name}/initialisePublic`)
                    ]).then(() => {
                        router.get(store.getters[`${NavbarStoreModule.name}/navbarEntries`], true);
                    });
                }
            });
        } else {
            publicPagePromise = Promise.resolve();
        }

        publicPagePromise.then(() => {
            if(initPromise === undefined) {
                console.log('Loading Authenticated view...');
                //Prepare the error handlers for the Identify API. 403, show the no authz VM. All other errors assume bad SSO redirect to /login
                const handle403 = () => {
                    const message = configObject.authorizationFailedMessage && configObject.authorizationFailedMessage.length > 0 ? configObject.authorizationFailedMessage : undefined;
                    console.log('Handling 403...');
                    noAuthzVm(
                        vuetifyInstance,
                        message,
                        dark
                    );
                };
                const handleAuthN = (redirectUrl) => {
                    console.log('Redirecting somewhere...', redirectUrl);
                    window.location = redirectUrl;
                };
                const handleServerError = () => {
                    console.log('Handling Server error...');
                    noAuthzVm(
                        vuetifyInstance,
                        'Internal Server Error! Please try again or contact support.',
                        dark
                    );
                };

                //4. Fire off the initialise request
                initPromise = store.dispatch(`${UserStoreModule.name}/initialise`, { handleAuthN, handle403, handleServerError, queryParams, isLiveSessionTrackingDisabled })
                    .then(() => {
                        //What is the userType?
                        const userType = store.getters[`${UserStoreModule.name}/userType`];
                        //4. Initialise the TemplateRegistry
                        TemplateRegistry.init(DefaultPages.templates);
                        //4.1 TODO: If we have a client who overrides the standard pages we call INIT with their Registry here...
                        console.log('TemplateRegistry keys', TemplateRegistry.getKeys());
                        //5. Fire off the authz dependent initialisers
                        return Promise.all([
                            store.dispatch(`${FilterStoreModule.name}/initialise`, { skipUserFilters: userType === 'ANONYMOUS' }),
                            store.dispatch(`${NavbarStoreModule.name}/initialise`),
                            store.dispatch(`${ConfigStoreModule.name}/initialise`),
                            store.dispatch(`${PolicyStoreModule.name}/initialise`),
                        ]);
                    })
                    .then(() => { //6. If some features are turned on, fetch and process their initialisers
                        //Configure the Navbar and Router
                        if(router.router){
                            console.log('resetting router...');
                            router.reset();
                        }
                        const isAnonymousPortalUser = store.getters[`${UserStoreModule.name}/isAnonymousPortalUser`];
                        router.get(store.getters[`${NavbarStoreModule.name}/navbarEntries`], false, {
                            home: store.getters[`${ConfigStoreModule.name}/homeRouteOverrides`]
                        });
                        if (store.getters[`${FeatureStoreModule.name}/isFollowingEnabled`] && !isAnonymousPortalUser) {
                            store.dispatch(`${FollowStoreModule.name}/initialise`);
                        }
                        if (store.getters[`${FeatureStoreModule.name}/isFoldersEnabled`] && !isAnonymousPortalUser) {
                            store.dispatch(`${FolderStoreModule.name}/initialise`);
                        }
                    });
            }
        }).then(() => {
            initPromise.then(() => {
                //7. Now we have good authz and an initialised data environment pull up the main App VM.
                vuetifyInstance = vuetify(configObject);
                new Vue({
                    router: router.get(),
                    store,
                    vuetify: vuetifyInstance,
                    el: '#app',
                    render: h => h(App)
                });
            }).then(() => {
                // Finally nuke the loading VM
                destroyLoadingVm();
            });
        });
    });
});
