import store from '../store';
import { RouterSetup, EventBus, EventTypes, FeatureStoreModule, UserStoreModule } from '@bluematrix/ui-library';
import pages from './pages';

const extraOptions = {
    mode: 'history',
    scrollBehavior(to, from, savedPosition){
        to.meta.fromHistory = savedPosition !== null;
        const stillOnHome = to.name === 'home' && from.name === 'home';
        to.meta.preventRerender = stillOnHome;
        if (stillOnHome || savedPosition){
            return savedPosition;
        }
        return { x: 0, y: 0 };
    }
};

const checkAndHandleForward = (to) => {
    if (to.query && to.query.forward) {
        console.log('Found query and matched \'forward\'');
        const forward = to.query.forward;
        const genericId = to.query['generic-link-id'];
        const cases = {
            'secure-link': `/secure-link/${to.query['render-type']}/${to.query['secure-link-id']}/${to.query['doc-id']}`,
            'generic-link': `/secure-link/${to.query['render-type']}/${to.query['doc-id']}${genericId ? `?genericLinkId=${genericId}` : ''}`,
            'embed': `/embed?fid=${to.query['fid']}${to.query['wid'] ? `&wid=${to.query['wid']}` : ''}`,
            'mention': `/mention/${to.query['type']}/${to.query['mention']}`,
        };
        console.log('processing forward', forward, cases);
        const path = cases[forward];
        if (path) {
            const nextObj = {path};
            console.log('Forwarding to: ', nextObj);
            return nextObj;
        }
    }
};
const checkAndHandleHome = (to) => {
    if(to.name === 'home' && !to.query.contentType){
        to.query.contentType = 'FEEDS';
        return to;
    }
};
let firstPolicyLoad = true;
const checkAndHandlePolicies = (to) => {
    if(to && to.meta && to.meta.policyUuids && to.meta.policyUuids.length > 0) {
        console.log('Emitting policyUuids', to.meta.policyUuids);
        const emit = () => EventBus.$emit(EventTypes.SHOW_POLICY_UUIDS, to.meta.policyUuids);
        if(firstPolicyLoad) { //Due to mounting order we, if this is the first route and there's a policy to emit, then we want to wait a little bit.
            setTimeout(emit, 500);
            firstPolicyLoad = false;
        } else {
            emit();
        }
    }
};

export default {
    router: undefined,
    firstPolicyLoad: true,
    reset() {
        this.router = undefined;
        pages.reset();
    },
    get(navbarEntries, isPublic, overrides) {
        const hasAuthority = store.getters[`${UserStoreModule.name}/hasAuthority`];
        if(!this.router){
            console.log('Setting up router...');
            const compiledPages = [ ...pages.get(navbarEntries, isPublic, overrides) ];
            //We have to promote some data to the meta block so that it's available in the Router's Guards.
            compiledPages.forEach(p => {
                p.meta = {
                    adminOnly: !!p.adminOnly,
                    ...(p.meta || {breadcrumbs: []})
                };
            });
            this.router = RouterSetup.setup(store, compiledPages, extraOptions);
            console.log('RouterSetup ', this.router);
            //Note: We must always call next when using this hook
            // eslint-disable-next-line
            this.router.beforeEach((to, from, next) => {
                let nextObj = checkAndHandleForward(to);//check query if we have a forward
                try {
                    console.log('Router to', to, ' and ', from);
                    const userInitialised = store.getters[`${UserStoreModule.name}/initialised`];
                    const isPublicPagesEnabled = store.getters[`${FeatureStoreModule.name}/isPublicPagesEnabled`];
                    if(isPublicPagesEnabled && !userInitialised && to.meta.permissionUserType !== 'PUBLIC'){ //TODO: Add check here to see if the requested page is public or not!
                        nextObj = { name: 'landing' };
                    } else if (to.meta.adminOnly) {
                        if (!hasAuthority('ROLE_PORTAL_ADMIN', 'ROLE_PORTAL_USER_ADMIN') && to.name !== 'accessDenied') {
                            console.log(`Access to ${to.name} is denied!`);
                            nextObj = { name: 'accessDenied' };
                        }
                    }
                    // to prevent extra route change when going home
                    if (!nextObj) {
                        nextObj = checkAndHandleHome(to);
                    }
                    checkAndHandlePolicies(to);
                    //For the back button in cookie policies. if app is being accessed for first time or there is a refresh from will
                    //have a null name  with the path of / before going to the TO route. seeing we dont have access to history this
                    //may be the best way to tell if the navigation is within the app.
                    if (to && from && from.name !== null && to.meta){
                        to.meta.useGoBack = true;
                    }
                    const isPageType = (location) => location && location.meta && ['ANALYSTS', 'SECTORS_V2', 'SECTORS_V2'].indexOf(location.meta.pageType) !== -1;
                    if (isPageType(from) && store.state && store.state.manageDrawer && store.state.manageDrawer.manageDrawerOpen){
                        store.commit('setShowFilterWarning', true);
                        return false;
                    }else{
                        EventBus.$emit(EventTypes.TOGGLE_MANAGE_DRAWER, false);
                    }
                    next(nextObj);
                } catch (e) {
                    console.error('Error during routing', e);
                    next({ name: 'error' });
                }
            });
        }
        return this.router;
    }
};
