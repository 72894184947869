<template>
    <div class="full-screen authorized-app">
        <transition name="fade">
            <loading-splash-v1 v-if="rootLoading" />
        </transition>
        <div
            class="skip-main"
            tabindex="0"
            role="link"
            @click="applyFocus"
            @keypress.enter="applyFocus"
        >
            Skip to main content
        </div>

        <app-bar
            v-if="!hideAll && (!hideHeaderComputed || (hideHeaderComputed && isPublicChildRouteComputed))"
            id="app-bar-header"
            ref="appBar"
            class="portal-container"
            :color="navbarColor"
            :dark="navbarDark"
            :breadcrumbs="breadcrumbs"
            :is-public="isPublicRouteComputed"
            :no-links="isPublicRouteComputed && !isPublicChildRouteComputed && $route.meta.pageType !== 'REGISTRATION_ATTESTATION_PAGE'"
        />

        <app-drawer v-if="!hideAll && !isPublicLandingPageComputed" />

        <template v-if="!hideAll && distributionButtonEnabledComputed">
            <distribution-drawer-v1 :app="false" />
        </template>

        <manage-drawer-v1
            v-if="!hideAll && !isAnonymousPortalUser"
            style="height: 100% !important;"
            :filter-entries="filterEntries"
            :app="false"
        />
        <v-main
            id="main-container"
            ref="mainContainer"
            tabindex="-1"
        >
            <slot name="banner" />
            <div
                class="d-sr-only text-h4"
                role="heading"
                aria-level="1"
            >
                {{ headerValue }}
            </div>
            <v-container
                fill-height
                align-start
                fluid
                class="portal-container pa-0 ma-auto main-container authorized-app__main-container"
                :class="{'pt-5': !hideHeaderComputed && !$scopedSlots.banner && !isPublicLandingPageComputed}"
                :style="{minHeight: `${windowMinHeight}px!important`}"
            >
                <keep-alive
                    max="5"
                    :exclude="['Report', 'VueperSlide', 'Admin', 'SearchResults']"
                >
                    <router-view
                        :key="rootRouteKeyComputed"
                        :route-depth="0"
                        :app-render="appRender"
                        class="router-view"
                        @processLogin="processLogin($event)"
                    />
                </keep-alive>

                <div
                    v-if="!hideAll"
                    style="position: fixed; bottom: 20px; right: 0; z-index: 10"
                    :style="{right: $vuetify.breakpoint.mdAndDown ? '0' : '10px'}"
                >
                    <scroll-to-v1
                        class="mb-2"
                        color="branding-color-1"
                        position="relative"
                    />
                    <scroll-to-v1
                        v-if="displayScrollDownBtnConfig"
                        class="mb-2"
                        color="branding-color-1"
                        position="relative"
                        direction="down"
                        check-footer
                    />
                    <edit-view-btn-v1
                        color="branding-color-3"
                        :elevation="null"
                    />
                </div>
            </v-container>
        </v-main>
        <v-dialog
            v-if="!hideAll"
            v-model="showFilterWarningComputed"
            width="400"
        >
            <v-card>
                <v-card-title>{{ $vuetify.lang.t('$vuetify.filterWarning.title') }}</v-card-title>
                <v-card-text>{{ $vuetify.lang.t('$vuetify.filterWarning.text') }}</v-card-text>
                <v-card-actions>
                    <v-btn
                        block
                        color="branding-color-1"
                        class="white--text"
                        @click="showFilterWarningComputed = false"
                    >
                        {{ $vuetify.lang.t('$vuetify.buttons.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <footer-v1
            v-if="!hideAll"
            :powered-by-img-src="`/images/bm-logo-${footerDark? 'light': 'dark'}-poweredby.svg`"
            :dark="footerDark"
            footer-boiler-plate-key="$vuetify.client.FOOTER_BOILER_PLATE"
            :background-color="footerColor"
            :hide-powered-by-logo="isHidePoweredByLogo"
            :policy-dark="altBackgroundDark"
            :policy-background-color="altBackgroundColor"
            :show-standard-privacy-policy-link="showStandardPrivacyPolicyLink"
            :app="false"
        />
    </div>
</template>

<script>
import { VContainer, VMain } from 'vuetify/lib';
import AppDrawer from '@/components/AppDrawer';
import AppBar from '@/components/AppBar';
import debounce from 'lodash.debounce';
import {
    ConfigModuleMixin,
    EventBus,
    EventTypes,
    FeatureModuleMixin,
    FilterConfigurationModuleMixin,
    DistributionDrawerV1,
    FooterV1,
    LoadingSplashV1,
    ManageDrawerV1,
    NavbarModuleMixin,
    RootModuleMixin,
    RouterMixin,
    ScrollToV1,
    UserModuleMixin,
    LocaleMixin,
    EditViewBtnV1,
    PublicPageMixin,
    UserPermissionMixin,
    AxiosSetup
} from '@bluematrix/ui-library';
import { mapGetters } from 'vuex';
import PathLookupMixin from '@/mixins/PathLookupMixin';
import {encode} from 'js-base64';
const axios = AxiosSetup.axiosInstance;


export default {
    name: 'AuthorizedApp',
    components: {
        AppDrawer,
        AppBar,
        LoadingSplashV1,
        VMain,
        VContainer,
        ScrollToV1,
        FooterV1,
        ManageDrawerV1,
        DistributionDrawerV1,
        EditViewBtnV1
    },
    mixins: [PublicPageMixin, RootModuleMixin, UserModuleMixin, NavbarModuleMixin, ConfigModuleMixin, RouterMixin, FilterConfigurationModuleMixin, FeatureModuleMixin, LocaleMixin, PathLookupMixin, UserPermissionMixin],
    props: {
        breadcrumbs: {
            type: Array,
            default: () => []
        },
    },
    beforeCreate() {
        EventBus.$on(EventTypes.MENTION_CLICKED, (mention) => {
            this.handleMention(mention);
        });
    },
    async mounted() {
        console.log('AuthorizedApp.vue mounted', this.$route);
        window.localStorage.removeItem('goTo');
        this.clearCrumbs();
        localStorage.removeItem('CRUMB_BASE_OVERRIDE');
        this.setupReportClickEvent();
        this.setupModelClickEvents();
        EventBus.$on(EventTypes.ADMIN_PROPERTY_SAVED, this.adminBusListener);
        EventBus.$on(EventTypes.PAGE_REFRESH, this.handleNavClick);
        this.handleWindowMinHeight();
        window.addEventListener('resize', this.handleWindowMinHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowMinHeight);
    },
    destroyed() {
        EventBus.$off(EventTypes.ADMIN_PROPERTY_SAVED, this.adminBusListener);
    },
    data: function () {
        return {
            appRender: true,
            headerValue: 'Home',
            hidePersistentToast: false,
            persistentToast: undefined,
            windowMinHeight: undefined
        };
    },
    computed: {
        ...mapGetters(['hideAll']),
        showFilterWarningComputed:{
            get(){
                return this.$store.state.showFilterWarning;
            },
            set(val){
                this.$store.commit('setShowFilterWarning', val);
            }
        },
        rootRouteKeyComputed(){
            if(['home', 'sitedataconfig', 'navbar'].indexOf(this.$route.name) !== -1){
                return this.$route.path;
            }
            return this.$route.fullPath;
        },
        distributionButtonEnabledComputed() {
            let enabled = false;
            if(!this.hideHeaderComputed && !this.isPublicRouteComputed && !this.isAnonymousPortalUser){
                if(this.isDistributionEnabled){
                    enabled = this.userType === 'INTERNAL';
                }
                if(this.isDistributionCartEnabled){
                    const distributionEnabledOption = this.getDistributionCartEnabledOption;
                    enabled = distributionEnabledOption === 'DISTRIBUTION_ALLOW_ALL' || this.userType === 'INTERNAL';
                }
            }
            return enabled;
        },
    },
    watch: {
        '$route': {
            handler(route) {
                if(route) {
                    if (route.name === 'REPORT') {
                        setTimeout(() => this.handleWindowMinHeight(), 1000);
                    }
                    const queryParams = route.query;
                    const securityQueryCodes = ['cusip', 'sedol', 'isin', 'bbg'];
                    if(queryParams && Object.keys(queryParams).some(k => securityQueryCodes.indexOf(k) !== -1)){
                        console.log('User requesting security code lookup...');
                        let searchType = undefined;
                        let symbol = undefined;
                        for (let i = 0; i < securityQueryCodes.length; i++) {
                            searchType = securityQueryCodes[i];
                            symbol = queryParams[searchType];
                            if(symbol && symbol.length > 0) {
                                break;
                            } else {
                                searchType = undefined;
                                symbol = undefined;
                            }
                        }
                        if(searchType && symbol){
                            this.handleSecurityLookup(searchType, decodeURIComponent(symbol));
                        }
                    }
                }
            },
            immediate: true,
            deep: true
        },
    },
    methods: {
        handleSecurityLookup: debounce( function(searchType, symbol) {
            axios.post('/api/reference/security/v1/resolve', { searchType, symbol })
                .then(response => {
                    if(response && response.data) {
                        console.log(`Found a match for ${searchType}=${symbol}`, response.data);
                        const mention = {
                            firmId: response.data.firmId,
                            creatorId: response.data.creatorId,
                            type: 'SECURITY'
                        };
                        EventBus.$emit(EventTypes.MENTION_CLICKED, mention);
                    } else {
                        console.log(`No match found for ${searchType}=${symbol}`);
                    }
                });
        }, 250),
        setupModelClickEvents(){
            EventBus.$on(EventTypes.MODEL_ORIGINAL_CLICKED, (url) => {
                window.location = url;
            });
            EventBus.$on(EventTypes.MODEL_PDF_CLICKED, (url) => {
                window.open(url, '_blank').focus();
            });
        },
        setupReportClickEvent(){
            EventBus.$on(EventTypes.REPORT_CLICKED, (document, options) => {
                let url;
                if(document.restrictionsBypassToken) {
                    const encodedToken = encodeURIComponent(document.restrictionsBypassToken);
                    url = `/report/${document.encrypt}/${encodedToken}`;
                } else {
                    url = `/report/${document.encrypt}`;
                }
                if(document.recommendationId) {
                    const encodedRecommendationId = encodeURIComponent(document.recommendationId);
                    url = `${url}?recommendationId=${encodedRecommendationId}`;
                }
                const location = { path: url };
                if(['REPORT'].includes(this.$route.name)){
                    this.clearCrumbs();
                }
                if (options && options.viewAsPdf) {
                    Object.assign(location, {query: {override: 'PDF' }});
                }
                if(this.$route.meta.breadcrumbs && this.$route.meta.breadcrumbs.length > 1){
                    localStorage.setItem('CRUMB_BASE_OVERRIDE', JSON.stringify(this.breadcrumbs));
                }else{
                    localStorage.removeItem('CRUMB_BASE_OVERRIDE');
                }
                this.currentPath = location.path;
                this.addReportCrumb({document, path: location.path});
                this.$router.push(location);
            });
        },
        applyFocus(){
            const ref = this.$refs.mainContainer;
            if(ref){
                ref.$el.focus();
            }
        },
        handleNavClick: debounce(function(page){
            if (this.currentPath === page.path){
                this.appRender = false;
                this.$nextTick(() => {
                    this.appRender = true;
                    window.scrollTo({
                        top: 0
                    });
                });
            }
            this.currentPath = page.path;
        }, 500),
        processLogin(){
            this.$router.push({name:'home'});
        },
        adminBusListener(options) {
            let opt = options || {};

            if (this.hidePersistentToast) {
                opt = {...opt, showNormalToast: true};
                this.persistentToast = undefined;
                this.showToast(opt);
                return;
            }

            if (!opt.showNormalToast) {
                this.persistentToast = opt;
            } else if (this.persistentToast) {
                setTimeout(() => {
                    this.showToast({...this.persistentToast});
                }, 2000);
            }
            this.showToast(opt);
        },
        showToast(opt) {
            this.$toasted.clear();
            let duration, actions, message, type, position;
            if (opt.showNormalToast) {
                duration = 2000;
                actions = [];
                message = opt.message;
                type = 'success';
                position = 'bottom-right';
            } else {
                duration = 43200000;
                message = this.$vuetify.lang.t('$vuetify.pageReload.message', `${opt.message || ''} ${opt.message && opt.message.length ? '. ' : ''}`);
                actions = [
                    {
                        text: this.$vuetify.lang.t('$vuetify.pageReload.action'),
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                            location.reload();
                        }
                    },
                    {
                        text: this.$vuetify.lang.t('$vuetify.pageReload.hideMessage'),
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                            this.hidePersistentToast = true;
                        }
                    }
                ];
                type = 'info';
                position = 'top-center';
            }
            this.$toasted.show(
                message,
                {
                    type,
                    duration,
                    className: 'config-property-toast',
                    singleton: true,
                    position,
                    icon: opt.icon,
                    action: [...actions]
                });
        },
        //Added debounce due to the event bus event is being called multiple times at times for some reason
        handleMention: debounce(function(mention) {
            console.log('handleMention', mention);
            const type = mention.type;
            const currentPath = this.$route.path;
            const metaPageType = this.$route.meta?.pageType;
            const linkReferences = this.$route.meta?.linkReferences;
            let found = false;
            const path = this.$route.matched.reduceRight((path, match) => {
                const matchPath = match.path.replace(/(\/:.*)/s, '');
                if(currentPath.startsWith(matchPath) && !found){
                    found = true;
                    return matchPath;
                }
                return path;
            }, currentPath);
            const options = {
                ANALYST: 'ANALYSTS',
                SINGLE_ANALYST: 'ANALYSTS',
                ANALYSTS: 'ANALYSTS',
                SECURITY: 'COMPANIES',
                SECTOR: 'SECTORS',
                SMART_TAG: 'ADVANCED_SEARCH',
            };
            const option = options[type];
            //Check to see if option is the start of meta for versioned mention pages. this will allow us to version all mentions if needed
            // but is only really usefully when coming from its only type
            const pageType = option && metaPageType && metaPageType.startsWith(option)? metaPageType : option;
            let rrp = this.getFullPathByPageTypePathAndMention({pageType, mention, path, entries: this.navbarEntries, linkReferences});
            if(!rrp && pageType){
                console.log(`vue No RRP found for pageType: ${pageType} using one of the "single" pages`);
                rrp = `/${pageType.replace('_V2', '').toLowerCase()}`;
            }
            if (pageType) {
                let query = undefined;
                if(type === 'SMART_TAG'){
                    rrp = '/results';
                    query = {
                        smartTags: encode(JSON.stringify([{id: mention.smartTagId, levelId: mention.creatorId }]))
                    };
                }
                this.goTo({
                    path: rrp,
                    ...mention,
                    query
                });
            }

        }, 250),
        handleWindowMinHeight() {
            const $el = document.getElementById('app-bar-header');
            if($el && $el.getBoundingClientRect){
                const appBarBox = $el.getBoundingClientRect();
                const navHeight = appBarBox.bottom + 10;
                this.windowMinHeight = window.innerHeight - navHeight;
            }
        }
    }
};
</script>

<style lang="scss">

.fill-height {
    min-height: 100vh !important;
    height: unset !important;
}

@media (-webkit-device-pixel-ratio: 1.5) {
    :root {
        zoom: 0.95;
    }
}

@media (-webkit-device-pixel-ratio: 2.0) {
    :root {
        zoom: 0.90;
    }
}

@media (-webkit-device-pixel-ratio: 2.5) {
    :root {
        zoom: 0.85;
    }
}
</style>

<style lang="scss" scoped>
.full-screen {
    width: 100%;
    height: 100%;
}
.router-view{
    width: 100%;
}
/*these two entries below are the class that will trigger the keyboard navigation - needed for ADA compliance */
.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}

.skip-main:focus, .skip-main:active {
    color: #fff;
    background-color: blue;
    left: auto;
    top: auto;
    width: fit-content;
    height: auto;
    overflow: auto;
    margin: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 2px solid #ffffff;
    text-align: center;
    font-size: 14px;
    z-index: 999;
}
</style>
