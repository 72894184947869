import {v4 as uuid} from 'uuid';
import {
    EventBus,
    EventTypes,
} from '@bluematrix/ui-library';
import { decode } from 'js-base64';
const noTraceUuid = () => `no-trace-${uuid()}`;
const DefaultPages = {
    templates: {
        'PUBLIC_LANDING_PAGE': () => import('../views/PublicLandingPage.vue'),
        'PUBLIC_LANDING_PAGE_SPLASH': () => import('../views/PublicLandingPageSplash.vue'),
        'EMBED_CONTAINER': () => import('../views/EmbeddedContainer.vue'),
        'DASHBOARD': () => import('../views/Dashboard.vue'),
        'REPORT': () => import('../views/Report.vue'),
        'SECURE_LINK': () => import('../views/Report.vue'),
        'GENERIC_LINK': () => import('../views/Report.vue'),
        'SEARCH_RESULTS': () => import('../views/SearchResults.vue'),
        'ERROR': () => import('../views/Error.vue'),
        'ACCESS_DENIED': () => import('../views/AccessDenied.vue'),
        'NOT_FOUND': () => import('../views/NotFound.vue')
    },
    pages: [
        {
            name: 'EMBED_CONTAINER',
            path: '/embed',
            props: route => ({ widgetUuid: route.query.wid, filterUuid: route.query.fid }),
            i18nKey: undefined,
            isSubMenu: false,
            showInNavbar: false,
            noCrumb: true,
            public: true
        },
        {
            name: 'DASHBOARD',
            path: '/dashboard',
            i18nKey: '$vuetify.router.mySettings',
            isSubMenu: false,
            exact: true,
            showInNavbar: false,
            public: false
        },
        {
            name: 'SUBSCRIPTIONS',
            path: '/subscriptions_default',
            i18nKey: '$vuetify.router.subscriptions',
            isSubMenu: false,
            exact: true,
            showInNavbar: false,
            public: false,
            props: {
                fallbackName: 'Subscriptions',
                i18nKey: '$vuetify.router.subscriptions'
            }
        },
        {
            name: 'REPORT',
            path: '/report/:encrypt/:restrictionsBypassToken?',
            i18nKey: '$vuetify.router.report',
            isSubMenu: false,
            showInNavbar: false,
            homeCrumbOnly: true,
            public: true
        },
        {
            name: 'MENTION',
            path: '/mention/:type/:encrypt',
            i18nKey: '$vuetify.router.mention',
            isSubMenu: false,
            showInNavbar: false,
            homeCrumbOnly: true,
            public: true,
            redirect: (to) => {
                console.log('Processing mention', to.params);
                const { type, encrypt= '' } = to.params;
                let encryptValue;
                const simpleReg = /(([0-9]*_[0-9]*))/gm;
                const isNotBlank = (str) => str && str.trim().length > 0;
                const checkAndSet = (str) => {
                    if(isNotBlank(str)) {
                        const isSimplifiedRouteParam = simpleReg.test(str);
                        if(isSimplifiedRouteParam) {
                            const [firmId, creatorId] = str.split('_');
                            encryptValue = {firmId, creatorId};
                        }
                    }
                };

                //Check if it's a simplified route due to styles cant use base64
                checkAndSet(encrypt);
                if (!encryptValue && isNotBlank(encrypt)) {
                    const decodeValue = decode(encrypt);
                    checkAndSet(decodeValue);
                    if (!encryptValue) {
                        try{
                            encryptValue = JSON.parse(decodeValue);
                        }catch (e){
                            console.error('Failed to decode mention encrypt', e);
                            encryptValue = {};
                        }
                    }
                }

                setTimeout(() => {
                    EventBus.$emit(EventTypes.MENTION_CLICKED, {skipRelativeLinkLookup: true, type: (type || '').toUpperCase(), ...encryptValue });
                }, 500);
                return {path: '/'};
            },
            afterEnter: () => {

            }
        },
        {
            name: 'SECURE_LINK',
            path: '/secure-link/:renderType/:secureLinkUuid/:documentEncrypt',
            props: route => ({ renderType: route.params.renderType, secureLinkUuid: route.params.secureLinkUuid, documentEncrypt: route.params.documentEncrypt, pageUuid: route.params.secureLinkUuid }),
            i18nKey: '$vuetify.router.report',
            isSubMenu: false,
            showInNavbar: false,
            public: true
        },
        {
            name: 'GENERIC_LINK',
            path: '/secure-link/:renderType/:documentEncrypt',
            props: route => ({ renderType: route.params.renderType, genericLinkId: route.query.genericLinkId, documentEncrypt: route.params.documentEncrypt, pageUuid: route.params.documentEncrypt }),
            i18nKey: '$vuetify.router.report',
            isSubMenu: false,
            showInNavbar: false,
            public: true
        },
        {
            name: 'SEARCH_RESULTS',
            i18nKey: '$vuetify.router.searchResults',
            path: '/results',
            isSubMenu: false,
            showInNavbar: false,
            public: false,
            props: {
                showSearchResultAsTitle: true,
                allowAdvanceSearch: true,
                allowAdvanceSearchReload: false,
                allowAdvanceSearchSaving: true
            },
        },
        {
            name: 'ERROR',
            path: '/error',
            isSubMenu: false,
            showInNavbar: false,
            public: true
        },
        {
            name: 'ACCESS_DENIED',
            path: '/accessDenied',
            isSubMenu: false,
            showInNavbar: false,
            public: true
        },
        {
            path: '/links2'
        },
        //THE NOT FOUND PAGE ALWAYS HAS TO GO LAST!!!
        {
            name: 'NOT_FOUND',
            path: '*',
            isSubMenu: false,
            showInNavbar: false,
            public: true
        }
    ]
};

const withPageUUID = (defaultPages) => {
    const pages = {
        ...defaultPages,
        pages: defaultPages.pages.map((page) => {
            const pageId = noTraceUuid();
            const originalProps = page.props;
            return {
                ...page,
                id: pageId,
                props: (route) => {
                    let existingProps = {};
                    if (typeof originalProps === 'function') {
                        existingProps = originalProps(route);
                    } else if (originalProps) {
                        existingProps = originalProps;
                    }
                    if (!existingProps.pageUuid) {
                        existingProps.pageUuid = pageId;
                    }
                    return {
                        ...existingProps
                    };
                }
            };
        })
    };
    return pages;
};



export default withPageUUID(DefaultPages);
