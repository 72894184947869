<template>
    <v-navigation-drawer
        v-if="render"
        v-model="drawer"
        class="nav-drawer-menu"
        app
        right
        :width="drawerWidthComputed"
        temporary
    >
        <v-window v-model="currentWindow">
            <v-window-item :value="windowOptions.MAIN_WINDOW">
                <v-list
                    class="pt-0"
                    :class="{'mb-10 pb-16': $vuetify.breakpoint.smAndDown}"
                >
                    <div class="app-drawer-header pt-0 pb-2">
                        <v-row
                            justify="end"
                            class="mb-8"
                        >
                            <v-btn
                                class="mt-7 mr-7"
                                icon
                                @click="drawer=false"
                            >
                                <v-icon>fa fa-times</v-icon>
                                <span class="d-sr-only">{{ $vuetify.lang.t('$vuetify.buttons.close') }}</span>
                            </v-btn>
                        </v-row>

                        <navbar-entry-list-item
                            v-if="myPortalComputed && !isAnonymousPortalUser"
                            class="nav-drawer-menu__link"
                            :page="myPortalComputed"
                            @subMenuClick="subMenuClick"
                        />
                        <navbar-entry-list-item
                            v-if="subscriptionsComputed"
                            class="nav-drawer-menu__link"
                            :page="subscriptionsComputed"
                            @subMenuClick="subMenuClick"
                        />
                    </div>
                    <template v-for="page in navPagesComputed">
                        <navbar-entry-list-item
                            :key="`drawer-link-${page.id || page.name}`"
                            class="nav-drawer-menu__link"
                            :page="page"
                            @subMenuClick="subMenuClick"
                        />
                    </template>
                </v-list>
            </v-window-item>
            <v-window-item
                :value="windowOptions.SUB_WINDOW"
                class="nav-drawer-menu__submenu fill-height"
            >
                <v-card
                    v-if="currentPage"
                    flat
                    class="nav-drawer-menu__submenu fill-height"
                >
                    <v-card-actions>
                        <v-btn
                            icon
                            @click="subMenuClose($event)"
                        >
                            <v-icon>
                                fa-arrow-right
                            </v-icon>
                            <span class="d-sr-only">{{ $vuetify.lang.t('$vuetify.buttons.close') }}</span>
                        </v-btn>

                        <v-card-title class="text--secondary text-truncate">
                            {{ nestedDrawerTitle }}
                        </v-card-title>
                        <v-spacer />

                        <v-btn
                            icon
                        >
                            <v-icon>
                                fa-search
                            </v-icon>
                            <span class="d-sr-only">{{ $vuetify.lang.t('$vuetify.buttons.search') }}</span>
                        </v-btn>
                    </v-card-actions>
                    <v-divider />
                    <component
                        :is="nestedDrawerComponent"
                        v-bind="{ singleColumn: true, relativeRootPath: nestedDrawerComponentPath, children: nestedDrawerChildren, isDrawer: true, pageUuid: currentPage.id, fullPath: currentPage.props.fullPath}"
                    />
                </v-card>
            </v-window-item>
        </v-window>
    </v-navigation-drawer>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import { VNavigationDrawer, VList, VListItem, VListItemTitle, VContainer, VIcon, VCard, VToolbar, VBtn, VDivider, VSpacer  } from 'vuetify/lib';
import { LinkMixin } from '@bluematrix/ui-library';
import NavbarEntryListItem from '@/components/NavbarEntryListItem';
import PagesMixin from '@/mixins/PagesMixin';

const WINDOW_OPTIONS = {
    MAIN_WINDOW: 'MAIN_WINDOW',
    SUB_WINDOW: 'SUB_WINDOW'
};
export default {
    name: 'AppDrawer',
    components: { NavbarEntryListItem, VNavigationDrawer, VList, VListItem, VListItemTitle, VContainer, VIcon, VCard, VToolbar, VBtn, VDivider, VSpacer },
    mixins: [ LinkMixin, PagesMixin ],
    data: () => ({
        currentWindow: WINDOW_OPTIONS.MAIN_WINDOW,
        windowOptions: WINDOW_OPTIONS,
        nestedDrawer: false,
        nestedDrawerTitle: undefined,
        nestedDrawerComponent: undefined,
        nestedDrawerComponentPath: undefined,
        nestedDrawerChildren: undefined,
        currentPage: undefined,
        render: true
    }),
    computed: {
        ...mapState(['showPublicNav', 'showPublicBackToLanding']),
        drawerWidthComputed(){
            return this.currentWindow === WINDOW_OPTIONS.SUB_WINDOW? 375 : undefined;
        },
        drawer: {
            get () {
                return this.$store.state.drawer;
            },
            set (val) {
                if(!val){
                    this.currentWindow=WINDOW_OPTIONS.MAIN_WINDOW;
                }
                this.setDrawer(val);
            },
        },
        myPortalComputed(){
            return this.findAndClone('DASHBOARD');
        },
        subscriptionsComputed() {
            if(this.canAccessSubscriptions){
                return this.findAndClone('SUBSCRIPTIONS');
            }
            return undefined;
        }
    },
    watch: {
        '$route':{
            handler(){
                this.setDrawer(false);
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        ...mapMutations(['setDrawer']),
        findAndClone(pageName){
            let clonedPage;
            const page = this.pages.find(p => p.name === pageName);
            if(page){
                clonedPage = JSON.parse(JSON.stringify(page));
                if(clonedPage){
                    clonedPage.showInNavbar = true;
                    clonedPage.showArrow = true;
                }
            }
            return clonedPage;
        },
        subMenuClick (e, page) {
            this.currentWindow = WINDOW_OPTIONS.SUB_WINDOW;
            this.nestedDrawer = true;
            this.nestedDrawerComponent = page.subMenuComponent;
            const {fallbackName, i18nKey} = page.meta;
            let name = fallbackName;
            if(i18nKey){
                const translation = this.$vuetify.lang.t(i18nKey);
                if (!translation.startsWith('$vuetify')){
                    name = translation;
                }
            }
            this.nestedDrawerTitle = name;
            this.nestedDrawerComponentPath = page.props.relativeRootPath;
            this.nestedDrawerChildren = page.props.children;
            this.currentPage = page;
            e.preventDefault();
        },
        subMenuClose (e) {
            this.currentWindow = WINDOW_OPTIONS.MAIN_WINDOW;
            e.preventDefault();
            this.nestedDrawer = false;
            this.nestedDrawerComponent = undefined;
            this.nestedDrawerTitle = undefined;
            this.nestedDrawerComponentPath = undefined;
            this.nestedDrawerChildren = undefined;
            this.currentPage = undefined;
            this.render = false;
            //Fixes weird render issue on going back to main content
            this.$nextTick(() => this.render = true);
        }
    },
};
</script>

<style lang="scss" scoped>
.app-drawer-header, .nav-drawer-menu__submenu, .v-navigation-drawer__content{
    background-color: var(--v-alt-background-color-base) !important;
}
</style>
<style lang="scss">
.app-drawer-header{
    background-color: var(--v-alt-background-color-base) !important;
    .v-list-item--active{
        &::before{
            background: var(--v-alt-background-color-base)!important;
        }
    }
}
.nav-drawer-menu{
    z-index: 100000;
     &__link , &__link a, .group-submenu-item-link__link, .group-submenu-item__title{
         color: #000000!important;
         background: #FFFFFF!important;
     }
    .group-submenu, .nav-drawer-menu__submenu, .v-window-item  {
        height: 100%!important;
    }
    .v-navigation-drawer__content{
        background-color: var(--v-alt-background-color-base) !important;
    }
}
</style>
