/**
 Created by IntelliJ IDEA.
 User: Edward Michniak
 Date: 15/10/2020
 Time: 14:11
 **/
import Vue from 'vue';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
Vue.use(PerfectScrollbar);
