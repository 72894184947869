/**
 Created by IntelliJ IDEA.
 User: Edward Michniak
 Date: 03/07/2020
 Time: 16:53
 **/
export default {
    globalSearch: {
        searchLabel: 'Búsqueda todo'
    }
};
