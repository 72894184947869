/**
 Created by IntelliJ IDEA.
 User: Edward Michniak
 Date: 15/10/2020
 Time: 14:12
 **/
import Vue from 'vue';
import Toasted from 'vue-toasted';
Vue.use(Toasted, {
    iconPack: 'fontawesome',
    containerClass: 'v-application',
    position: 'bottom-right',
    keepOnHover: true,
    duration: 3500
});
