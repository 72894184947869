<template>
    <div>
        <v-list-item
            v-if="page.mailto"
            text
            :href="page.mailto"
            tabindex="-1"
        >
            <v-list-item-title tabindex="0">
                {{ textComputed }}
            </v-list-item-title>
        </v-list-item>
        <v-list-item
            v-else-if="!page.isSubMenu && page.showInNavbar"
            text
            :exact="page.exact"
            :to="generateToLink(page)"
            tabindex="-1"
            @click="handleLinkClick($event, page)"
        >
            <v-list-item-title tabindex="0">
                {{ textComputed }}
                <v-icon
                    v-if="page.showArrow"
                    class="pl-2"
                    style="font-size: 18px;"
                >
                    fa-caret-right
                </v-icon>
            </v-list-item-title>
        </v-list-item>
        <v-list-item
            v-else-if="page.isSubMenu && page.showInNavbar"
            text
            tabindex="-1"
            @click="subMenuClick($event, page)"
        >
            <v-list-item-title tabindex="0">
                {{ textComputed }}
                <v-icon
                    class="pl-2"
                    style="font-size: 18px;"
                >
                    fa-caret-right
                </v-icon>
            </v-list-item-title>
        </v-list-item>
    </div>
</template>

<script>
import {LinkMixin, LocaleMixin} from '@bluematrix/ui-library';
export default {
    name: 'NavbarEntryListItem',
    mixins: [ LinkMixin, LocaleMixin],
    props: {
        page: {
            type: Object,
            required: true
        }
    },
    computed: {
        textComputed() {
            return this.translateValue(this.page);
        }
    },
    methods: {
        subMenuClick(e, page) {
            this.$emit('subMenuClick', e, page);
        }
    }
};
</script>

<style scoped>

</style>
