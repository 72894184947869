<template>
    <v-main :style="{height: 'calc(100vh - 65px)'}">
        <slot name="banner" />
        <buyside-dashboard-v1 />
    </v-main>
</template>

<script>
import { BuysideDashboardV1 } from '@bluematrix/ui-library';
export default {
    name: 'BuysideApp',
    components: { BuysideDashboardV1 }
};
</script>

<style lang="scss" scoped>
</style>
