<template>
    <v-app>
        <component
            :is="appComputed"
            v-bind="appPropsComputed"
            :breadcrumbs="crumbsComputed"
        >
            <template
                v-if="showMaintenanceBanner || featureBanner"
                v-slot:banner
            >
                <bm-banner v-if="showMaintenanceBanner"
                           :model-value.sync="showMaintenanceBanner"
                           class="bm-banner__app bm-banner__bcp banner-z-index"
                           :class="bannerMarginClass"
                           :app="true"
                           :text="maintenanceBanner"
                           dismissible
                           color="bm-light-blue"
                           dark
                           icon="fa-exclamation"
                />

                <template v-if="bannerTextComputed">
                    <bm-banner
                        :model-value.sync="featureBanner"
                        class="bm-banner__app banner-z-index"
                        :class="bannerMarginClass"
                        app
                        :text="bannerTextComputed"
                        dismissible
                        color="warning"
                        dark
                        icon="fa-exclamation"
                    />
                </template>
            </template>
        </component>
    </v-app>
</template>

<script>
import { VApp } from 'vuetify/lib';
import { UserModuleMixin, FeatureModuleMixin, LocaleMixin, NavbarModuleMixin, ConfigModuleMixin, BmBanner, PublicPageMixin} from '@bluematrix/ui-library';
import UnauthorizedApp from '@/UnauthorizedApp';
import AuthorizedApp from '@/AuthorizedApp';
import BuysideApp from '@/BuysideApp.vue';
const REMOVE_COOKIE_LIST = [];
export default {
    name: 'App',
    components: {BuysideApp, AuthorizedApp, UnauthorizedApp, BmBanner, VApp},
    mixins: [ UserModuleMixin, FeatureModuleMixin, LocaleMixin, NavbarModuleMixin, ConfigModuleMixin, PublicPageMixin ],
    created() {
        REMOVE_COOKIE_LIST.forEach(cookie => this.$cookies.remove(cookie));
        document.title =  this.findAndTranslateMustaches(this.siteTitle || '');
    },
    mounted() {
        this.maintenanceBanner = this.$cookies.get('site-maintenance') || null;
        this.showMaintenanceBanner = !!(this.maintenanceBanner && (this.maintenanceBanner || '').trim().length);
        this.featureBanner = this.isPartialMaintenanceModeEnabled;
    },
    data:() => ({
        showMaintenanceBanner: false,
        featureBanner: false,
        maintenanceBanner : null
    }),
    computed: {
        bannerMarginClass() {
            if (this.isPublicRouteComputed) {
                return undefined;
            }
            return 'bm-banner__app--margin-top';
        },
        bannerTextComputed() {
            let text = undefined;
            if(this.isPartialMaintenanceModeEnabled){
                text = this.translateValue({ i18nKey: 'PARTIAL_MAINTENANCE_BANNER_TEXT', fallbackName: '$vuetify.banners.PARTIAL_MAINTENANCE_BANNER_TEXT' });
            }
            return text;
        },
        appComputed(){
            if(this.firmGroupBuyside){
                return 'BuysideApp';
            }
            if(this.isPublicPagesEnabled && !this.user){
                return 'UnauthorizedApp';
            }
            return 'AuthorizedApp';
        },
        appPropsComputed(){
            const props = {
                BuysideApp: {},
                UnauthorizedApp: {
                    appRender:true,
                    fallbackName: 'Unauthorized'
                },
                AuthorizedApp: {}
            };
            return props[this.componentComputed];
        },
        crumbsComputed() {
            if(this.isPublicRouteComputed){
                return [];
            }
            const crumbs = this.$route.meta.breadcrumbs || [];
            let clonedCrumbs;
            const crumbOverride = localStorage.getItem('CRUMB_BASE_OVERRIDE');
            let updatePreviousSetCrumb = false;
            if(['REPORT'].includes(this.$route.name) && crumbOverride && crumbOverride.length){
                clonedCrumbs = JSON.parse(crumbOverride);
                updatePreviousSetCrumb = true;
            }else{
                clonedCrumbs = JSON.parse(JSON.stringify(crumbs));
            }
            if (this.crumbs.length) {
                this.crumbs.forEach(c => clonedCrumbs.push(c));
            }
            const filtered = clonedCrumbs.filter(c => c.path);
            if(filtered.length){
                if(updatePreviousSetCrumb){
                    filtered[filtered.length - 2].disabled = false;
                }
                filtered[filtered.length - 1].disabled = true;
            }
            return filtered;
        },
        currentLangComputed(){
            return this.getUserPreference('lang');
        }
    },
    watch: {
        '$route':{
            handler(val){
                this.handleBrowserTabTitle(val);
            },
            immediate: true,
            deep: true
        },
        'currentLangComputed':{
            handler(){
                this.handleBrowserTabTitle(this.$route);
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        handleBrowserTabTitle(route){
            let titleSet = false;
            const setTitle = (text) => {
                document.title = this.findAndTranslateMustaches(this.siteTitle || '') + ' - ' + text;
                this.headerValue = text;
                titleSet = true;
            };
            if(route){
                if (route.path === '/' && this.unauthorized) {
                    setTitle('Unauthorized');
                    return;
                }
                if(route.name === 'REPORT'){
                    const crumbs = (this.crumbsComputed || []);
                    let lastCrumb;
                    if (crumbs.length) {
                        lastCrumb = crumbs[crumbs.length - 1];
                    }
                    if(lastCrumb.text){
                        setTitle((lastCrumb.text || '').replace('Report: ', ''));
                        return;
                    }
                    if(lastCrumb.text){
                        setTitle((lastCrumb.text || '').replace('Report: ', ''));
                        return;
                    }
                }
                if(route.name && route.meta){
                    const name = this.translateValue(route.meta);
                    if (name){
                        const pageName = (name.trim()).split(' ')
                            .map(w => w.replace(w[0], w[0].toUpperCase()))
                            .join(' ');
                        setTitle(pageName);
                    }
                }
            }
            if (!titleSet){
                document.title =  this.findAndTranslateMustaches(this.siteTitle || '');
            }
        },
    }
};
</script>

<style scoped lang="scss">
.banner-z-index {
    z-index: 7 !important;
}
</style>

<style lang="scss">
@import '~flag-icons/css/flag-icons.min.css';
@import "~@bluematrix/ui-library/src/styles/global_styles.scss";

.authorized-app .v-main__wrap > .bm-banner__app--margin-top{
    margin-top: 5px!important;
}
.authorized-app .v-main__wrap > .bm-banner__app--margin-top ~ .bm-banner__app--margin-top{
    margin-top: 0!important;
}
.v-application * {
    letter-spacing: 0!important;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.v-app-bar--fixed {
    width: 100% !important;
}

.v-input input::placeholder {
    color: rgba(0, 0, 0, 0.6) !important;
}

//*:focus-visible:not(input) {
//    outline-offset: -2px;
//    outline: var(--v-focus-visible-outline-color-base) solid 3px !important;
//}

button.vueperslides__bullet {
    height:15px;
    width:15px;
}

.portal-container .container, .privacy-view__title, .privacy-view__text {
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
}

@media(max-width: 699px) {
    .portal-container .container, .privacy-view__title, .privacy-view__text {
        max-width: 100vw;
    }
}

@media(min-width: 700px) {
    .portal-container .container, .privacy-view__title, .privacy-view__text {
        max-width: 650px;
    }
}

@media(min-width: 750px) {
    .portal-container .container, .privacy-view__title, .privacy-view__text {
        max-width: 735px;
    }
}

@media(min-width: 850px) {
    .portal-container .container, .privacy-view__title, .privacy-view__text {
        max-width: 845px;
    }
}

@media(min-width: 960px) {
    .portal-container .container, .privacy-view__title, .privacy-view__text {
        max-width: 900px;
    }
}

@media(min-width: 980px) {
    .portal-container .container, .privacy-view__title, .privacy-view__text {
        max-width: 918.75px;
    }
}

@media(min-width: 1280px) {
    .portal-container .container, .privacy-view__title, .privacy-view__text {
        max-width: 1271.25px;
    }
}

@media(min-width: 1580px) {
    .portal-container .container, .privacy-view__title, .privacy-view__text {
        max-width: 1481.25px;
    }
}

.portal-container .container--fluid {
    max-width: 100%;
}

.config-property-toast {
    .action {
        color: white!important;
    }
}
</style>
