/**
 BlueMatrix LLC
 User: Edward Michniak
 Date: 01/09/2020
 Time: 12:49
 **/
import { TemplateRegistry } from '@bluematrix/ui-library';
import NavbarGenerator from '@/router/NavbarGenerator';
import DefaultPages from '@/router/DefaultPages';
import store from '../store';
import { UserStoreModule } from '@bluematrix/ui-library';
const hasAuthority = (...authorities) => store.getters[`${UserStoreModule.name}/hasAuthority`](...authorities);
const groupsWithNoPage = ['GROUP', 'GROUP_WITH_TITLE', 'GROUP_WITH_BANNER'];
function processDefaultRoutes(isPublic, addTo, home){
    return DefaultPages.pages
        .filter(p => isPublic === true? p.public : true)
        .map(r => {
            r.component = TemplateRegistry.getTemplate(r.name);
            if((addTo || []).includes(r.name)){
                const crumb = {path: r.path.replace(/(\/:.*)/s, ''), i18nKey: r.i18nKey};
                r.meta = {
                    fallbackName: r.fallbackName,
                    i18nKey: r.i18nKey,
                    breadcrumbs: !r.noCrumb ? [{path: home.path, i18nKey: home.i18nKey, text: home.fallbackName}, crumb] : []
                };
                if(r.homeCrumbOnly){
                    r.meta.breadcrumbs.pop();
                }
            }
            return r;
        });
}

/**
 * Processes all children routes
 * @param entry
 * @param updatedEntry
 * @param home
 * @param isPublic
 * @returns {*}
 */
function processChildren({ entry, updatedEntry, home, isPublic}){
    if(entry.children && entry.children.length > 0){
        const children = (entry.children || []).map((child) => {
            const parentCrumbs = updatedEntry.meta && updatedEntry.meta.breadcrumbs? JSON.parse(JSON.stringify(updatedEntry.meta.breadcrumbs)) : undefined;
            return processEntry({
                entry: child,
                home,
                showInNavbar:false,
                directParent: updatedEntry,
                parentCrumbs,
                isPublic
            });
        });

        updatedEntry.children = children;
        updatedEntry.props.children = children;
    }
    return updatedEntry;
}

function getRedirectCookie(cookieKey){
    const getUserPreference =  store.getters[`${UserStoreModule.name}/getUserPreference`];
    let cookieValue = undefined;
    if(cookieKey){
        cookieValue = getUserPreference(cookieKey);
    }
    return cookieValue;
}

function handleRedirect(updateEntry) {
    //If Group page with no content then we find the first child we can redirect to
    if (groupsWithNoPage.indexOf(updateEntry.pageType) !== -1) {
        const childAllowsRedirect = (child) => child.props.pageOptions && child.props.pageOptions.ALLOW_GROUP_REDIRECT_ON_CLICK === true;
        let redirectChild = (updateEntry.children || []).find(c => (childAllowsRedirect(c) || [...groupsWithNoPage, 'EXTERNAL_URL'].indexOf(c.pageType) === -1));

        if (redirectChild) {
            updateEntry.redirect = () => {
                let redirectPath = getRedirectCookie(`${updateEntry.props.pathKey}-favorite-page`);
                if (redirectPath) {
                    redirectPath = redirectPath.replaceAll('%2F', '/');
                    redirectChild = (updateEntry.children || []).find(c => {
                        return redirectPath.endsWith(c.path);
                    });
                }
                return ({path: redirectChild.path});
            };
        }
    }
}


function buildBreadCrumbs(updateEntry, {entry, parentCrumbs, showInNavbar, home}) {
    if ((entry.pageType !== 'HOME' && entry.pageType !== 'EXTERNAL_URL')) {
        //Build out all the breadcrumbs for given route
        if (parentCrumbs) {
            const path = updateEntry.path;
            updateEntry.path = parentCrumbs[parentCrumbs.length - 1].path + path;
        }

        const disable = ([...groupsWithNoPage, 'EXTERNAL_URL'].indexOf(entry.pageType) !== -1) || entry.disableView;
        const crumb = {path: updateEntry.path.replace(/(\/:.*)/s, ''), i18nKey: updateEntry.i18nKey, text: updateEntry.fallbackName, disabled: disable};
        updateEntry.hasChildren = !!(entry.children && entry.children.length);
        const breadcrumbs = !showInNavbar && parentCrumbs ? [...parentCrumbs, crumb] : [{path: home.path, i18nKey: home.i18nKey, text: home.fallbackName}, crumb];
        updateEntry.meta = {
            ...(updateEntry.meta || {}),
            breadcrumbs
        };
        // for pages that have a child param page for single pages
        if (updateEntry.generateChildCrumbs) {
            updateEntry.children.forEach(child => child.meta = {...(child.meta || {}), breadcrumbs});
        }
    }
}

/**
 * Processes and generates a vue route entry
 * @param entry
 * @param home
 * @param showInNavbar
 * @param directParent
 * @param parentCrumbs
 * @param isPublic
 * @returns {null}
 */

function processEntry({ entry, home, showInNavbar, directParent, parentCrumbs, isPublic}) {
    let updateEntry = NavbarGenerator.generate(entry, showInNavbar, directParent, home);
    if (updateEntry){
        updateEntry.meta = {
            ...(updateEntry.meta || {}),
            pageId: updateEntry.id
        };
        buildBreadCrumbs(updateEntry, {entry, parentCrumbs, showInNavbar, home});

        updateEntry = processChildren({ entry, updatedEntry: updateEntry, home, isPublic});
        //Build out props page where sub navigation bar is enabled
        const ALLOW_GROUP_SUB_NAV = !!(updateEntry.props && updateEntry.props.pageOptions && updateEntry.props.pageOptions.ALLOW_GROUP_SUB_NAV);
        if(updateEntry.hasChildren && ALLOW_GROUP_SUB_NAV){
            const {fallbackName, i18nKey, path, name} = updateEntry || {};
            const parentLink = directParent && directParent.pageType === 'PUBLIC_LANDING_PAGE'? [{ fallbackName, i18nKey, path, name, hideFromSubmenu: true }] : [];
            updateEntry.props.children  = [...parentLink, ...(updateEntry.children || [])];
        }
        handleRedirect(updateEntry);
    }
    return updateEntry;
}
const processMissingPages = (isPublic, pages, home) =>{
    if (!isPublic){
        const requiredRootPageTypes = ['COMPANIES', 'SECTORS_V2', 'ANALYSTS'];
        const missingRequiredPages = requiredRootPageTypes.reduce((missingRoutes, requiredPageType) => {

            const hasPageTypeInRoot = pages.some(p => p.pageType === requiredPageType || `${p.pageType}_V2` === requiredPageType);
            if(!hasPageTypeInRoot){
                const type = requiredPageType.toLowerCase();
                const relativeRootPath = type.replace('_v2', '');
                const fallbackName = (relativeRootPath[0].toUpperCase() + relativeRootPath.substr(1));
                missingRoutes.push({pageType: requiredPageType, disableView: true, fallbackName: fallbackName, relativeRootPath, nameI18nKey: `$vuetify.router.${type}`});
            }
            return missingRoutes;
        }, []);
        return missingRequiredPages.map(n => processEntry({ entry: n, home}));
    }
    return [];
};
const processNavbar = (isPublic, navbarEntries, home) =>{
    let pages = [];
    if (navbarEntries && navbarEntries.length > 0) {
        const completeNavbar = [
            ...navbarEntries
        ];

        if(!isPublic && hasAuthority('ROLE_PORTAL_ADMIN', 'ROLE_PORTAL_USER_ADMIN')){
            completeNavbar.push({ pageType: 'ADMIN' });
        }

        if(!isPublic && hasAuthority('CAN_MANAGE_REGISTRATION_QUEUE')){
            completeNavbar.push({ pageType: 'REGISTRATION_QUEUE' });
        }

        pages = completeNavbar
            .map(n => processEntry({ entry: n, home, showInNavbar: true, isPublic}))
            .flat()
            .filter(page => !!page);
        if(isPublic){
            const publicLandingPage = pages.find(p => p.pageType === 'PUBLIC_LANDING_PAGE');
            home = publicLandingPage || home;
            home.path = '/';
        } else {
            pages.unshift(home);
        }
    }
    return pages;
};
export default {
    cache: undefined,
    reset() {
        this.cache = undefined;
    },
    get(navbarEntries, isPublic, overrides) {

        if (!this.cache) {
            if (!navbarEntries) {
                throw new Error('No pages cached and no navbar entries provided!');
            }
            console.log('Beginning Navbar Construction... ', isPublic);
            const then = new Date().getTime();

            let home = {};
            if(!isPublic){
                /**
                 *    homeRouteOverrides: (state) => ({
                 *             showInNavbar: !getBooleanValue(state, 'SITE_HIDE_HOME', false),
                 *             fallbackName: getTextValue(state, 'SITE_HOME_TEXT'),
                 *             nameI18nKey: getTextValue(state, 'SITE_HOME_TRANSLATION'),
                 *         }),
                 */
                const configuredHome = navbarEntries.find(ne => ne.pageType === 'HOME');
                if(configuredHome){
                    if (overrides.home?.nameI18nKey){
                        configuredHome.i18nKey = overrides.home.nameI18nKey;
                    }
                    configuredHome.showInNavbar = !configuredHome.hideNavLink;
                    home = NavbarGenerator.generate(configuredHome);
                    navbarEntries = navbarEntries.filter(ne => ne.pageType !== 'HOME');
                }else{
                    home = NavbarGenerator.generate({ pageType: 'HOME', ...(overrides.home || {}) });
                }
            }
            const pages = processNavbar(isPublic, navbarEntries, home);


            const processedMissing = processMissingPages(isPublic, pages, home);

            const pagesToAdd = [ 'REPORT', 'SEARCH_RESULTS', 'MENTION' ];
            if (!isPublic && !hasAuthority('ROLE_ANONYMOUS_PORTAL_USER')){
                pagesToAdd.push('SUBSCRIPTIONS', 'DASHBOARD');
            }
            this.cache = [
                ...pages,
                ...processedMissing,
                ...processDefaultRoutes(isPublic, pagesToAdd, home)
            ];
            console.log('Completed in', new Date().getTime() - then, 'ms', this.cache);
        }
        return this.cache;
    }
};
