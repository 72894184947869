<template>
    <v-app-bar
        app
        flat
        class="portal-container portal-app-bar"
        :color="color"
        :style="{width: '100vw'}"
        :extension-height="extensionHeightComputed"
    >
        <v-container class="d-flex justify-space-between pa-0 ma-auto">
            <router-link
                to="/"
                class="my-auto"
            >
                <v-img
                    :src="logoComputed"
                    class="clickable align-self-center"
                    contain
                    height="60"
                    min-width="150"
                    max-width="250"
                />
            </router-link>


            <portal-search-v1
                v-if="$vuetify.breakpoint.lgAndUp && !isPublic"
                class="py-4 px-2 app-bar-portal-search"
                persist-store-module
                dense
                :clear-search="clearSearch"
                :min-width="$vuetify.breakpoint.mdAndDown ? '90%': 560"
                :width="$vuetify.breakpoint.mdAndDown ? '90%' : 750"
                :max-height="500"
                :show-label="false"
                @onSearch="onSearch"
            />
            <div class="d-flex">
                <email-cart-button-v1
                    v-if="showButtonsComputed"
                    :dark="dark"
                    class="align-self-center"
                    :use-icon="!!$vuetify.breakpoint.mdAndDown"
                    :badge-offset-x="30"
                    :badge-offset-y="8"
                    :use-branding-colors="true"
                    :icon-size="20"
                />
                <my-portal-v1
                    v-if="showButtonsComputed"
                    class="align-self-center"
                    route-to-dashboard="/dashboard"
                    :route-to-subscriptions="subscriptionsUrlComputed"
                    :dark="dark"
                    :use-icon="!!$vuetify.breakpoint.mdAndDown"
                />
                <login-btn-v1 class="my-auto mx-2"
                              :dark="dark"
                />
                <user-language
                    class="my-auto mx-2"
                    :dark="dark"
                    :append-lang-to-url="false"
                    :disable-options-by-page-meta="false"
                    :filter-options-by-page-meta="true"
                    :allowed-languages="allowPageLanguagesComputed"
                />
            </div>
        </v-container>
        <template
            v-if="showNavComputed"
            v-slot:extension
        >
            <v-card
                width="100%"
                height="100%"
                rounded="0"
                flat
                elevation="0"
            >
                <v-container class="pa-0">
                    <v-row no-gutters>
                        <v-col class="d-flex justify-center">
                            <nav-bar-v1
                                v-if="$vuetify.breakpoint.lgAndUp"
                                class="app-nav-bar-tabs"
                                :tabs="navPagesComputed"
                                emit-refresh
                                @onPathChange="onPathChange"
                            />
                            <portal-search-v1
                                v-if="$vuetify.breakpoint.mdAndDown && !isPublic"
                                class="py-1 pt-3 px-2 app-bar-portal-search"
                                persist-store-module
                                dense
                                :clear-search="clearSearch"
                                :width="$vuetify.breakpoint.mdAndDown ? '90%' : 800"
                                :max-height="500"
                                :show-label="false"
                                @onSearch="onSearch"
                            />
                        </v-col>
                    </v-row>
                </v-container>
                <v-container
                    v-if="breadcrumbs.length > 1"
                    class="pt-1 mt-1 pb-0"
                    style="background: #FFF;"
                    :class="$vuetify.breakpoint.smAndDown ? 'pl-4' : 'pl-0'"
                >
                    <breadcrumbs-v1
                        class="mt-0 pt-0 pl-0 ml-0 pr-2 pb-0"
                        :items="breadcrumbs"
                        :truncate-all-disabled="$vuetify.breakpoint.smAndDown"
                    />
                </v-container>
            </v-card>
        </template>
        <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown && showNavComputed"
            :color="dark? '#FFF' : '#000'"
            class="mt-3"
            @click="toggleDrawer"
        >
            <template v-slot:default>
                <v-btn
                    icon
                    :dark="dark"
                >
                    <v-icon>
                        fas fa-bars
                    </v-icon>
                    <span
                        class="d-sr-only"
                        v-text="'Menu Drawer'"
                    />
                </v-btn>
            </template>
        </v-app-bar-nav-icon>
    </v-app-bar>
</template>

<script>
import { mapState, mapMutations, createNamespacedHelpers } from 'vuex';
const configNamespacedMappers = createNamespacedHelpers('config');
import { VAppBar, VAppBarNavIcon, VContainer, VRow, VCol, VImg, VCard } from 'vuetify/lib';
import {LoginBtnV1, MyPortalV1, PortalSearchV1, BreadcrumbsV1, TestAutomationMixin, NavBarV1, LinkMixin, EmailCartButtonV1, UserLanguage, UserPermissionMixin} from '@bluematrix/ui-library';
import PagesMixin from '@/mixins/PagesMixin';
import {encode} from 'js-base64';
const isUndefined = (val) => val === undefined;
export default {
    name: 'AppBar',
    components: { LoginBtnV1, NavBarV1, MyPortalV1, PortalSearchV1, VAppBar, VAppBarNavIcon, VContainer, VRow, VCol, VImg, VCard, BreadcrumbsV1, EmailCartButtonV1, UserLanguage},
    mixins: [ LinkMixin, TestAutomationMixin, PagesMixin, UserPermissionMixin],
    props: {
        color: {
            type: String,
            required: false,
            default: () => 'background'
        },
        dark: {
            type: Boolean,
            required: false,
            default: () => false
        },
        noLinks: {
            type: Boolean,
            required: false,
            default: () => false
        },
        breadcrumbs: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            currentPage: null,
            activeTab: 0,
            currentTab: null,
            clearSearch: false,
        };
    },
    computed: {
        ...mapState(['drawer', 'showPublicNav', 'showPublicBackToLanding']),
        ...configNamespacedMappers.mapGetters(['logoLight', 'logoDark']),
        showButtonsComputed(){
            return !this.isPublic;
        },
        allowPageLanguagesComputed(){
            if (this.allowPortalLanguageSelectionOption === 'FILTER_UNSUPPORTED_TRANSLATION'){
                return this.$route.meta.langs;
            }
            return undefined;
        },
        hasLangRedirectComputed(){
            return ['REDIRECT_UNSUPPORTED_TRANSLATION'].includes(this.allowPortalLanguageSelectionOption);
        },
        extensionHeightComputed(){
            return this.breadcrumbs.length > 1 ? 85 : 60;
        },
        subscriptionsUrlComputed() {
            return this.showSubscriptionsInAppBar ? '/subscriptions_default' : undefined;
        },
        showNavComputed(){
            return !(this.noLinks || (this.isPublic && !this.showPublicNav));
        },
        logoComputed() {
            if(this.dark){
                return `/api/public/sitedata/binary/${this.logoDark}`;
            } else {
                return `/api/public/sitedata/binary/${this.logoLight}`;
            }
        },
    },
    watch: {
        getUserLangPreference :{
            handler(value){
                if (this.allowPortalLanguageSelection){
                    if (this.$vuetify.lang.current !== value){
                        this.$vuetify.lang.current = value;
                    }
                    const allowedLangs = this.$route.meta.langs;
                    if (this.hasLangRedirectComputed && allowedLangs && !allowedLangs.includes(value)){
                        const actions = {
                            'REDIRECT_UNSUPPORTED_TRANSLATION': () => {
                                this.$router.push('/').catch(() => {});
                            }
                        };
                        actions[this.allowPortalLanguageSelectionOption]();
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapMutations(['setDrawer']),
        onPathChange(path){
            if(path !== '/results'){
                this.clearSearch = true;
                this.$nextTick(() => this.clearSearch = false);
            }
        },
        toggleDrawer() {
            this.setDrawer(!this.drawer);
        },
        getRValueFromQuery(){
            const { query } = this.$route;
            if(!query.r) {
                return String(1);
            }
            return String(Number(query.r) + 1);

        },
        buildRouteObjBase({ textOnly, securities, analysts, smartTags, r}){
            if(!textOnly) {
                if (!isUndefined(securities) && securities.length > 0) {
                    console.log('securities', securities);
                    const securitiesParam = encode(JSON.stringify(securities));
                    return  { path: '/results', query: { securities: securitiesParam, r} };
                } else if (!isUndefined(analysts) && analysts.length > 0) {
                    console.log('analysts', analysts);
                    const analystsParam = encode(JSON.stringify(analysts));
                    return { path: '/results', query: { analysts: analystsParam, r} };
                } else if (!isUndefined(smartTags) && smartTags.length > 0) {
                    console.log('smartTags', smartTags);
                    const smartTagsParam = encode(JSON.stringify(smartTags));
                    return { path: '/results', query: { smartTags: smartTagsParam, r} };
                }
            }
            return undefined;
        },
        onSearch({firmId, searchString, textOnly, securities, analysts, smartTags, filterName}){
            console.log('onSearch', searchString, securities, analysts, smartTags, filterName);
            const r = this.getRValueFromQuery();
            let routeObj = this.buildRouteObjBase({textOnly, securities, analysts, smartTags, r});

            if (!routeObj && !isUndefined(searchString) && searchString.length > 0) {
                routeObj = { path: '/results', query: { search: encodeURIComponent(searchString), r} };
            }

            let filterNameParam;
            if(!isUndefined(filterName)){
                filterNameParam = encodeURIComponent(filterName);
            }

            if(routeObj) {
                if(!isUndefined(firmId)){
                    routeObj.query.firmId = firmId;
                }
                if(filterNameParam){
                    routeObj.query.filterName = filterNameParam;
                }
                this.$router.push(routeObj);
            } else {
                this.$router.push({ path: '/results', query: { r, ...(filterNameParam? {filterName: filterNameParam} : {}) } });
            }
        },
    },
};
</script>
<style lang="scss">
.portal-app-bar {
    z-index: 6 !important;
}
.my-portal.row {
    flex-wrap: nowrap;
    >* {
        align-self: center;
    }
}
.app-bar-portal-search{
    justify-content: center;
}
.clickable {
    cursor: pointer;
}
.custom-v-menu__no-elevation {
    box-shadow: none;
}
.app-nav-bar-tabs{
    width: 50vw;
}
.v-toolbar__content{
    height: 80px!important;
    padding-bottom: 20px!important;
}
@media(max-width: 800px) {
    .v-toolbar__content{
        padding-bottom: 10px!important;
    }
}
.v-toolbar__extension{
    padding:0 !important;
}
</style>
