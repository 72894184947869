import { locales, BmColorSchema} from '@bluematrix/ui-library';
import defaultLocales from '../locale';

const setTitle = (title) => {
    if(title){
        document.title = title;
    } else {
        document.title = 'Portal';
    }
};
export default function ({ siteTitle, altBackgroundColor, brandingColor1, brandingColor2, brandingColor3, splashBackgroundColor, focusVisibleOutlineColor, clientTranslations = {}}) {
    setTitle(siteTitle);
    let light = {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#ffffff',
        'alt-background-color': altBackgroundColor || '#F5F5F5',
        'branding-color-1': brandingColor1 || '#CFE1F5',
        'branding-color-2': brandingColor2 || '#000033',
        'branding-color-3': brandingColor3 || '#030303',
        'splash-background-color': splashBackgroundColor || '#2196F3',
        'focus-visible-outline-color': focusVisibleOutlineColor || '#ffa500',
        ...BmColorSchema
    };
    if (brandingColor1 && brandingColor2 && brandingColor3){
        window.localStorage.setItem('lightTheme', JSON.stringify(light));
    }else{
        const storedLightTheme = window.localStorage.getItem('lightTheme');
        if (storedLightTheme){
            try {
                light = JSON.parse(storedLightTheme);
            }catch (e) {
                console.log('Could not parse stored theme');
            }
        }
    }
    // create a master object of locales based on supported locales in ui-library
    const compiledLocales = Object.keys(locales).reduce((acc, key) => {
        const getLocales = (obj) => {
            return obj && obj[key] || {};
        };

        return {
            ...acc,
            [key]: {
                ...getLocales(defaultLocales),
                ...getLocales(locales),
                client: getLocales(clientTranslations)
            }
        };
    }, {});
    return {
        icons: {
            iconfont: 'fa'
        },
        breakpoint: {
            thresholds: {
                xs: 340,
                sm: 540,
                md: 1275,
                lg: 1600
            },
            scrollBarWidth: 16,
        },
        theme: {
            dark: false,
            themes: {
                light
            },
            options: {
                customProperties: true
            }
        },
        lang: {
            locales: compiledLocales,
            current: 'en'
        }
    };
}
