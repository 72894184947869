<template>
    <div :id="removeId? undefined:'authorization-failed'"
         class="authorization-failed"
         :class="color"
    >
        <div>
            <div class="d-flex justify-center">
                <v-icon class="authorization-failed__icon"
                        :style="colorStylesComputed"
                >
                    fa fa-exclamation-triangle
                </v-icon>
            </div>

            <div
                v-translate:html="messageComputed"
                class="authorization-failed__message ma-auto text-center"
                :class="{ 'white--text' : dark }"
            />
            <div class="d-flex justify-center">
                <!--  Using <button> instead of <bm-button> due to requirement of having to set up vue router for Vuetify  -->
                <button class="authorization-failed__retry v-btn px-3 py-2"
                        type="button"
                        :style="buttonStylesComputed"
                        @click="onRetryClick"
                >
                    {{ retryTextComputed }}
                </button>
            </div>
        </div>
        <loading-splash-v1 v-if="loading"
                           :color="color"
                           :dark="dark"
                           :opacity="1"
        />
    </div>
</template>

<script>
import { VIcon } from 'vuetify/lib';
import { LocaleMixin, LoadingSplashV1} from '@bluematrix/ui-library';
const cookie = 'unauthorized-portal-user';
export default {
    name: 'AuthorizationFailed',
    components: {VIcon, LoadingSplashV1},
    mixins: [LocaleMixin],
    props: {
        color: {
            type: String,
            required: false,
            default: () => 'splash-background-color'
        },
        message: {
            type: String,
            required: false,
            default: () => undefined
        },
        dark: {
            type: Boolean,
            required: false,
            default: () => true
        },
        removeId: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    created() {
        this.$cookies.set(cookie, true, 60 * 60 * 2, null, window.location.host);
    },
    data: () => ({
        loading: false
    }),
    computed: {
        messageComputed() {
            if (this.message) {
                return this.message;
            } else {
                return this.$vuetify.lang.t('$vuetify.unauthorized.page');
            }
        },
        iconColorComputed() {
            return this.dark ? 'white' : 'black';
        },
        retryTextComputed() {
            return this.translateValue({i18nKey: 'UNAUTHORIZED_RETRY_BTN', fallbackName: this.$vuetify.lang.t('$vuetify.general.tryAnotherLogin')});
        },
        retryUrlComputed() {
            return `/api/end-session?forward=${encodeURI(window.location.origin)}`;
        },
        colorStylesComputed(){
            return {color: `${this.iconColorComputed}!important`};
        },
        buttonStylesComputed(){
            return {color: `${this.iconColorComputed}!important`, border: `2px solid ${this.iconColorComputed}!important`};
        }
    },
    methods: {
        onRetryClick() {
            console.log(':::onRetryClick:::', this.$cookies.get(cookie));
            this.loading = true;
            this.$cookies.remove(cookie);
            this.$cookies.set(cookie, true, 1, null, window.location.host);
            setTimeout(() =>{
                console.log(':::removed cookie:::', this.$cookies.get(cookie));
                window.location.href = this.retryUrlComputed;
            }, 1000);
        }
    }
};
</script>

<style lang="scss" scoped>
.authorization-failed {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    .authorization-failed__icon, .authorization-failed__retry {

    }

    .authorization-failed__retry {

    }
}
</style>
