import pages from '@/router/pages';
import {ConfigModuleMixin, FeatureModuleMixin, UserModuleMixin, LocaleMixin} from '@bluematrix/ui-library';

const getPublicPages = ({pages, isPublic, showPublicBackToLanding, publicHome})  => {
    let publicPages = [];
    const publicLandingPage = pages.find(p => p.pageType === 'PUBLIC_LANDING_PAGE') || {};
    publicPages = (publicLandingPage.children || []).filter(p => !p.hideNavLink)
        .filter(p => p.pageType !== 'REGISTRATION_ATTESTATION_PAGE')
        .map(p => {
            p.showInNavbar = true;
            return p;
        });
    if (isPublic && showPublicBackToLanding){
        publicPages.unshift(publicHome);
    }
    return publicPages;
};
const getNavbarPages = ({pages, hasAuthority, canAccessSubscriptions} = {}) => {
    return (pages || []).filter(p => {
        let show  = false;
        if(p.showInNavbar){
            if(p.adminOnly && hasAuthority('ROLE_PORTAL_ADMIN', 'ROLE_PORTAL_USER_ADMIN')) {
                show = true;
            } else {
                show = !p.adminOnly;
            }

            if(p.pageType === 'SUBSCRIPTIONS' && !canAccessSubscriptions) {
                show = false;
            }
        }
        return show;
    });
};

/**
 * Reduces an array of pages by recursively reducing any children pages.
 * Only includes pages that match the user's language preference.
 *
 * @param {Array} pagesToReduce - The array of pages to be reduced.
 * @return {Array} The reduced array of pages.
 */
const reduceNavbarPages = ({pages, userLanguagePreference} = {}) => {
    return pages.reduce((pagesToShow, page) => {
        let children = [];
        const props = {...(page.props || {})};
        if (page.hideNavLink) {
            return pagesToShow;
        }
        if (page.children) {
            children = reduceNavbarPages({pages: page.children, userLanguagePreference});
        }
        if (props?.children) {
            props.children = reduceNavbarPages({pages: props.children, userLanguagePreference});
        }
        if (!page.lang || page.lang.split(',').includes(userLanguagePreference)) {
            pagesToShow.push({
                ...page,
                children,
                props
            });
        }
        return pagesToShow;
    }, []);
};

export default {
    mixins: [UserModuleMixin, ConfigModuleMixin, FeatureModuleMixin, LocaleMixin],
    props:{
        isPublic: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    data: () =>({
        pages: pages.get(),
    }),
    computed: {
        navbarKeyComputed(){
            return this.allowPortalLanguageSelection? `app-nav-bar__${this.getUserPreference('lang')}` : 'app-nav-bar';
        },
        contactUsComputed(){
            return {
                fallbackName: 'Contact Us',
                i18nKey: 'CONTACT_US',
                mailto: `mailto:${this.portalSupportEmail}?subject=${this.translateValue({fallbackName: 'Would like to request to help', i18nKey: 'CONTACT_US_SUBJECT'})}&body=${this.translateValue({fallbackName: '', i18nKey: 'CONTACT_US_BODY'})}`,

            };
        },
        publicHomeComputed(){
            const publicLandingPage = this.pages.find(p => p.pageType === 'PUBLIC_LANDING_PAGE') || {};
            const clonedPage = JSON.parse(JSON.stringify(publicLandingPage));
            clonedPage.fallbackName = 'Back to Landing';
            clonedPage.i18nKey=  '$vuetify.router.backToPublicLanding';
            clonedPage.children = [];
            clonedPage.showInNavbar = true;
            clonedPage.pageType = 'PUBLIC_BACK_HOME';
            clonedPage.isSubMenu = false;
            return clonedPage;
        },
        navPagesComputed(){
            let pages = [];
            if(this.isPublic){
                pages = getPublicPages({pages: this.pages, isPublic: this.isPublic, showPublicBackToLanding: this.showPublicBackToLanding, publicHome: this.publicHomeComputed});
            }else{
                pages = getNavbarPages({pages: this.pages, hasAuthority: this.hasAuthority, canAccessSubscriptions: this.canAccessSubscriptions});
                if (this.portalSupportEmail && this.appendPortalSupportEmailToNav){
                    pages.push(this.contactUsComputed);
                }
            }
            return reduceNavbarPages({pages, userLanguagePreference: this.getUserLangPreference});
        },
        adminPagesComputed(){
            const adminPage = (this.pages || []).find(p => p.pageType === 'ADMIN') || {};
            const pinnedPages = (adminPage.children || []).filter(p => p.pinned);
            const unpinnedPages = (adminPage.children || []).filter(p => !p.pinned).sort((a, b) => {
                const nameA = this.$vuetify.lang.t(a.i18nKey);
                const nameB = this.$vuetify.lang.t(b.i18nKey);
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            return pinnedPages.concat(unpinnedPages);
        },
        registrationNavPagesComputed() {
            if(this.isPublic){
                const publicLandingPage = this.pages.find(p => p.pageType === 'PUBLIC_LANDING_PAGE') || {};
                return (publicLandingPage.children || []).filter(p => p.pageType === 'REGISTRATION_ATTESTATION_PAGE' && !p.hideNavLink);
            }
            return [];
        },
        showSubscriptionsInAppBar() {
            return this.canAccessSubscriptions && this.getSubscriptionsIframeOption === 'SUBSCRIPTIONS_BUTTON';
        },
        canAccessSubscriptions() {
            return this.isSubscriptionsIframeEnabled && this.hasAuthority('CAN_EDIT_SUBSCRIPTIONS');
        }
    },
};
