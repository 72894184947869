<template>
    <div class="full-screen unauthorized-app">
        <template v-if="render">
            <app-bar
                v-if="showAppBar"
                ref="appBar"
                class="portal-container"
                :color="navbarColor"
                :dark="navbarDark"
                :breadcrumbs="[]"
                :is-public="true"
                :no-links="!showLinks"
            />
            <app-drawer v-if="$route.path !== '/'"
                        is-public
            />
            <v-main>
                <slot name="banner" />
                <div
                    class="d-sr-only text-h4"
                    role="heading"
                    aria-level="1"
                >
                    Unauthorized
                </div>
                <v-container v-if="!showUnauthorizedOnlyComputed"
                             style="min-height: 90vh"
                             align-start
                             fluid
                             class="portal-container pa-0 unauthorized-app__main-container"
                >
                    <router-view
                        :key="$route.fullPath"
                        :route-depth="0"
                        :app-render="appRender"
                        class="router-view"
                        @processLogin="processLogin($event)"
                    />
                </v-container>
                <authorization-failed v-else
                                      remove-id
                                      :dark="splashDark"
                                      :message="authorizationFailedMessage"
                />
            </v-main>
        </template>
        <footer-v1 :powered-by-img-src="`/images/bm-logo-${footerDark? 'light': 'dark'}-poweredby.svg`"
                   :dark="footerDark"
                   footer-boiler-plate-key="$vuetify.client.FOOTER_BOILER_PLATE"
                   :background-color="footerColor"
                   :hide-powered-by-logo="isHidePoweredByLogo"
                   :policy-dark="altBackgroundDark"
                   :policy-background-color="altBackgroundColor"
                   :show-standard-privacy-policy-link="showStandardPrivacyPolicyLink"
                   :app="false"
                   @processLogin="processLogin({...$event, noRedirect: false, fromFooter: true})"
        />
    </div>
</template>

<script>
import AppBar from '@/components/AppBar';
import { VContainer, VMain } from 'vuetify/lib';
import {
    ConfigModuleMixin,
    UserModuleMixin,
    FeatureModuleMixin,
    RouterMixin,
    RootModuleMixin,
    NavbarModuleMixin,
    FilterConfigurationModuleMixin,
    FooterV1, RenderMixin,
    PublicPageMixin
} from '@bluematrix/ui-library';
import AppDrawer from '@/components/AppDrawer';
import AuthorizationFailed from '@/views/AuthorizationFailed.vue';
const unauthorizedPortalUserCookie = 'unauthorized-portal-user';
export default {
    name: 'UnauthorizedApp',
    components: {AuthorizationFailed, AppDrawer, AppBar, VContainer, VMain, FooterV1},
    mixins: [ RootModuleMixin, UserModuleMixin, NavbarModuleMixin, ConfigModuleMixin, RouterMixin, FilterConfigurationModuleMixin, FeatureModuleMixin, RenderMixin, PublicPageMixin],
    mounted() {
        console.log('UnauthorizedApp.vue mounted', this.$route);
        this.unauthorizedLocal = !!this.$cookies.get(unauthorizedPortalUserCookie);
    },
    data: () => ({policy: undefined, unauthorizedLocal: false}),
    computed: {
        showAppBar() {
            return (!this.hideHeaderComputed || this.isPublicChildRouteComputed);
        },
        showLinks() {
            return this.isPublicChildRouteComputed && this.$route.meta.pageType !== 'REGISTRATION_ATTESTATION_PAGE';
        },
        showUnauthorizedOnlyComputed() {
            return !!(this.$route.path === '/' && (this.unauthorized || this.unauthorizedLocal));
        },
    },
    watch:{
        showUnauthorizedOnlyComputed:{
            immediate: true,
            handler: function (value, oldValue) {
                if (value !== oldValue) {
                    this.handleHiddenUnauthorized(value);
                }
            }
        },
    },
    methods: {
        handleHiddenUnauthorized(show) {
            //It's not key to interact with the DOM here directly but vue does not like it when trying to bind this through styling
            const display = show ? 'flex' : 'none';
            this.$nextTick(() => {
                setTimeout(() => {
                    const failedAuthz = document.getElementById('authorization-failed');
                    if (failedAuthz) {
                        failedAuthz.setAttribute('style', `display:${display}!important`);
                    }
                }, 250);
            });
        },
        processLogin(options = {}) {
            console.log('::::: processLogin', options);
            this.$cookies.remove(unauthorizedPortalUserCookie);
            const handle403 = () => {
                console.log('Handling 403...');
                this.unauthorizedLocal = true;
                this.$cookies.set(unauthorizedPortalUserCookie, true, 60 * 60 * 2, null, window.location.host);
            };

            const handleAuthN = (redirectUrl) => {
                console.log('Redirecting somewhere...', redirectUrl);
                this.redirectLocation(redirectUrl);
            };

            const handleServerError = () => {
                console.log('Handling Server error...');
                this.$toasted.error('Server Error - Please try again later');
            };
            const processLogin = () => {
                this.$store.dispatch('user/initialise', {handleAuthN, handle403, handleServerError}).then(() => {
                    console.log('Running then after initialise');
                    if (options && options.noRedirect !== true) {
                        this.redirectLocation('/');
                    }
                });
            };
            if (!options.policy && !options.url || (options.policy && options.policy === this.policy && options.fromFooter)) {
                processLogin();
            } else if (options.policy && !options.fromFooter) {
                this.policy = options.policy;
            }

        },
        redirectLocation(location) {
            setTimeout(() => window.location = location, 500);
        }
    }
};
</script>

<style lang="scss">

.fill-height {
    min-height: 100vh !important;
    height: unset !important;
}

@media (-webkit-device-pixel-ratio: 1.5) {
    :root {
        zoom: 0.95;
    }
}

@media (-webkit-device-pixel-ratio: 2.0) {
    :root {
        zoom: 0.90;
    }
}

@media (-webkit-device-pixel-ratio: 2.5) {
    :root {
        zoom: 0.85;
    }
}
</style>

<style lang="scss" scoped>
.full-screen {
    width: 100%;
    height: 100%;
}

.router-view {
    width: 100%;
}
</style>
