import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        drawer: false,
        showPublicNav: true,
        showPublicBackToLanding: true,
        showFilterWarning: false,
        hideAll: false
    },
    mutations: {
        setDrawer(state, payload) {
            state.drawer = payload;
        },
        setShowPublicNav(state, payload){
            state.showPublicNav = payload;
        },
        setShowPublicBackToLanding(state, payload){
            state.showPublicBackToLanding = payload;
        },
        setShowFilterWarning(state, payload){
            state.showFilterWarning = payload;
        },
        setHideAll(state, payload) {
            state.hideAll = payload;
        }
    },
    actions: {
    },
    getters: {
        showPublicNav: state => state.showPublicNav,
        showPublicBackToLanding: state => state.showPublicBackToLanding,
        hideAll: state => state.hideAll
    }
});
