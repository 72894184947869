/**
 BlueMatrix LLC
 User: Josh OBrian
 Date: 8/19/2021
 Time: 8:37 AM
 **/
import Vue from 'vue';
import VueMasonry from 'vue-masonry-css';

Vue.use(VueMasonry);
