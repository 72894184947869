/**
 * Check to see if entry has valid content type to check for mention type
 * @param entry
 * @param mentionType
 * @returns {boolean}
 */
const hasContentItemsToCheck = (entry, mentionType) => {
    if (entry.pageContentItems && entry.pageContentItems.length){
        if(!entry.pageContentItemType){
            return true;
        }
        const pageToMentionMapping ={
            ANALYSTS: 'ANALYST',
            COMPANIES: 'SECURITY',
            SECTORS: 'SECTOR'
        };
        const filterTypeToPageMapping = {
            ANALYSTS: 'ANALYSTS',
            SECURITIES: 'COMPANIES',
            SECTORS: 'SECTORS',
        };
        const pageTypeMinusVersion = (entry.pageType || '').split('_')[0];
        const allowedMentionType = pageToMentionMapping[pageTypeMinusVersion];
        const allowedPageFilterType = filterTypeToPageMapping[entry.pageContentItemType];
        if (allowedMentionType && allowedPageFilterType){
            return allowedMentionType === mentionType && allowedPageFilterType === pageTypeMinusVersion;
        }
    }
    return false;
};


export default {
    methods: {
        isPageType(type, {pageType}){
            return  pageType === type || pageType === `${type}_V2`;
        },
        getPathByPageType(navbarEntries, pageType) {
            return (navbarEntries || []).find(n => this.isPageType(pageType, n));
        },
        // eslint-disable-next-line
        findRelativePath(entry = {}, {pageType, mention, path, linkReferences, checkContentItems, skipRelativeLinkLookup}) {
            const {type, firmId, creatorId} = mention;
            let relativePath;
            if(this.isPageType(pageType, entry)){
                if (linkReferences && linkReferences.length && linkReferences.indexOf(entry.navbarEntryUuid) !== -1){
                    return entry.fullPath;
                }else if(!linkReferences || !linkReferences.length){
                    if(path === entry.fullPath && !skipRelativeLinkLookup){
                        return entry.fullPath;
                    }
                    if(checkContentItems && entry.filterPageContent){
                        let hasItem = false;
                        if (hasContentItemsToCheck(entry, type)){
                            hasItem = entry.pageContentItems.some(item => item.creatorId === creatorId && item.firmId === firmId);
                        }
                        if (entry.pageContentFirmIds && entry.pageContentFirmIds.length && (!entry.pageContentRegionFilters || !entry.pageContentRegionFilters.length)){
                            hasItem = entry.pageContentFirmIds.some(fId => fId === firmId);
                        }

                        if(hasItem){
                            return entry.fullPath;
                        }
                    }
                }

            }
            if (entry.children){
                (entry.children || []).some((child)=> {
                    const sendToPath = this.findRelativePath(child,  {
                        path,
                        mention,
                        pageType,
                        linkReferences,
                        checkContentItems,
                        skipRelativeLinkLookup
                    });
                    if(sendToPath){
                        relativePath = sendToPath;
                        return true;
                    }
                });
            }
            return relativePath;
        },
        getFullPathByPageTypePathAndMention({pageType, mention, path, entries, linkReferences}){
            let rrp;
            if(linkReferences && linkReferences.length){
                entries.some(entry => {
                    rrp = this.findRelativePath(entry, {
                        path,
                        pageType,
                        linkReferences,
                        mention,
                        checkContentItems: false
                    });
                    if(rrp){
                        return true;
                    }
                });
            }
            if(!rrp && !mention.skipRelativeLinkLookup){
                entries.some(entry => {
                    rrp = this.findRelativePath(entry, {
                        path,
                        pageType,
                        mention,
                        checkContentItems: false
                    });
                    if(rrp){
                        return true;
                    }
                });
            }
            if (!rrp){
                entries.some(entry => {
                    rrp = this.findRelativePath(entry, {
                        path,
                        pageType,
                        mention,
                        linkReferences,
                        checkContentItems: true,
                        skipRelativeLinkLookup: !!mention.skipRelativeLinkLookup
                    });
                    if(rrp){
                        return true;
                    }
                });
            }

            if(!rrp){
                const navbarEntry = this.getPathByPageType(entries, pageType);
                if(navbarEntry){
                    rrp = `/${navbarEntry.relativeRootPath}`;
                }
            }
            return rrp;
        }
    },
};
